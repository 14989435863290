import "./WordamentSolver.page.css";

import React, { useEffect, useState } from "react";

import { ProgressIndicator, TextField } from "@fluentui/react";

import HeaderComponent from "../components/Header.component";
import { PageDescription } from "../models/PageDescription.model";
import { Dictionary } from "../models/wordament/Dictionary.model";
import { GameBoard } from "../models/wordament/GameBoard.model";

interface ProgressBarState {
  label: string;
  description: string;
  percentComplete: number;
  visible: boolean;
}

function WordamentSolverComponent() {
  const [getDictionary, setDictionary] = useState(new Dictionary());
  useEffect(() => {
    fetch("/static_files/scrabble_words.txt")
      .then((response) => response.text())
      .then((text) => {
        setDictionary(
          Dictionary.fromArray(text.replace(/\r/g, "").split(/\n/))
        );
      });
  }, []);

  const [getGameBoard, setGameBoard] = useState<GameBoard | null>(null);
  useEffect(() => {
    const gameBoard = getGameBoard;
    if (gameBoard != null && Object.keys(gameBoard.testedWords).length == 0) {
      getAllRoutes(gameBoard);
    }
  }, [getGameBoard]);

  const [foundWords, setFoundWords] = useState<string[]>([]);
  const [tileInputValue, setTileInputValue] = React.useState("");
  const [getProgressBarState, setProgressBarState] =
    React.useState<ProgressBarState>({
      label: "",
      description: "",
      percentComplete: 0,
      visible: false,
    });

  // prevent non alpha keys
  const onChangeTileInputValue = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (!newValue || newValue.length <= 16) {
        setTileInputValue(newValue || "");
      }
    },
    []
  );

  const loadTiles = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key == "Enter") {
      const inputString = tileInputValue;
      if (inputString.length < 16) {
        // show error
      } else {
        setFoundWords([]);
        setGameBoard(
          GameBoard.fromArray(
            inputString.split(""),
            getDictionary,
            updateStatus
          )
        );
      }
    }
  };

  const getAllRoutes = async (gameBoard: GameBoard) => {
    setProgressBarState({
      ...getProgressBarState,
      visible: true,
    });
    await gameBoard.getAllRoutesAsync();
    setFoundWords(gameBoard.foundWords);
    setProgressBarState({
      ...getProgressBarState,
      visible: false,
    });
  };

  const updateStatus = (progress: number) => {
    const currentSate = getProgressBarState;
    const progressBarState: ProgressBarState = {
      ...currentSate,
      percentComplete: progress,
    };
    setProgressBarState(progressBarState);
  };

  const dictionaryLoaded = getDictionary.words.length > 0;
  const gameBoard = getGameBoard;
  const progressBarState = getProgressBarState;
  if (gameBoard != null && gameBoard?.foundWords.length > 0) {
    console.log(`found ${gameBoard?.foundWords.length} words`);
  }

  return (
    <>
      <HeaderComponent DynamicBackground={false} />
      <div className="flex-column flex-nowrap">
        <div className="experiment-title">Wordament Solver</div>
        {dictionaryLoaded ? (
          <div className="experiment-content flex-row flex-wrap flex-space-evenly flex-nowrap">
            <div className="flex-column flex-nowrap">
              <div className="column-title">Input Tile Letters</div>
              <div className="column-content">
                <TextField
                  className="tile-input"
                  value={tileInputValue}
                  onChange={onChangeTileInputValue}
                  onKeyPress={(e) => loadTiles(e)}
                />
                <div className="tiles flex-row flex-wrap flex-start">
                  {getGameBoard?.tiles.map((tile) => (
                    <div
                      key={`${tile.index}${tile.letter}`}
                      className="game-cell"
                    >
                      {tile.letter}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex-column found-words">
              <div className="column-title">Found Words:</div>
              <div className="column-content flex-row flex-wrap">
                {progressBarState.visible ? (
                  <div>
                    <ProgressIndicator
                      label={progressBarState.label}
                      description={progressBarState.description}
                      percentComplete={progressBarState.percentComplete}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {foundWords.length > 0
                  ? foundWords
                      .sort((a, b) => b.length - a.length)
                      .map((word) => (
                        <div key={word} className="found-word">
                          {word}
                        </div>
                      ))
                  : ""}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="things-left">
          <div className="things-left-title">Things left to do:</div>
          <ol className="things-left-list">
            <li>Speed up word search and render</li>
            <li>Allow multi character tiles</li>
            <li>
              Allow prefix and suffix tiles, such as &quot;PR-&quot; or
              &quot;-ED&quot;
            </li>
            <li>Add tile point values and sort by word score</li>
          </ol>
        </div>
      </div>
    </>
  );
}

const WordamentSolverDescription: PageDescription = {
  Title: "Wordament Solver",
  Description:
    "This is my implementation of a Wordament Solver to frustrate my colleagues.",
  ImagePath: "images/Wordament.jpg",
  Route: "wordament",
  Component: WordamentSolverComponent,
};

export { WordamentSolverComponent, WordamentSolverDescription };
