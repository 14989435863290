import { Tile } from "./Tile.model";

export class AdjacentTiles {
  up: Tile | null;
  down: Tile | null;
  left: Tile | null;
  right: Tile | null;
  upleft: Tile | null;
  upright: Tile | null;
  downleft: Tile | null;
  downright: Tile | null;

  constructor() {
    this.up = null;
    this.down = null;
    this.left = null;
    this.right = null;
    this.upleft = null;
    this.upright = null;
    this.downleft = null;
    this.downright = null;
  }
}
