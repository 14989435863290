import {
  WordamentSolverComponent,
  WordamentSolverDescription,
} from "./WordamentSolver.page";

export const Pages = {
  WordamentSolverComponent,
};

export const PageDescriptions = { WordamentSolverDescription };
