import "./Home.page.css";

import { ExperimentCardComponent } from "../components/ExperimentCard.component";
import HeaderComponent from "../components/Header.component";
import { PageDescriptions } from "../experiments";

export default function Home() {
  return (
    <>
      <HeaderComponent DynamicBackground={true} />
      <div className="app-splash">
        <div className="app-welcome">
          <div className="app-welcome-content">
            <div className="app-welcome-header">Welcome to my website!</div>
            <div>
              My name is Erich Ray and I like to tinker. This is a place for me
              to try some experiments and share the results with the world!
            </div>
          </div>
        </div>
        <div className="image-credit flex-row flex-end">
          <a href="https://unsplash.com/@spacex?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Photo by SpaceX on Unsplash
          </a>
        </div>
        <div className="app-body flex-column flex-nowrap">
          <div className="app-experiments-header">Experiments</div>
          <div className="app-experiments flex-row flex-wrap">
            <div className="app-experiment">
              {Object.keys(PageDescriptions).map((key) => (
                <ExperimentCardComponent
                  key={key}
                  Experiment={(PageDescriptions as any)[key]}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
