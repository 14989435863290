import "./ExperimentCard.component.css";

import * as React from "react";
import { useHistory } from "react-router-dom";

import { PageDescription } from "../models/PageDescription.model";

interface props {
  Experiment: PageDescription;
}

export const ExperimentCardComponent = ({ Experiment }: props) => {
  const history = useHistory();

  const experimentClick = (experiment: PageDescription) =>
    history.push(`/${experiment.Route}`);

  return (
    <div
      className="experiment-card flex-column flex-nowrap flex-center"
      onClick={() => experimentClick(Experiment)}
    >
      <div className="experiment-card-title">{Experiment.Title}</div>
      <div className="flex-row flex-nowrap">
        <img
          title="Image of the Wordament logo"
          className="experiment-card-image"
          src={Experiment.ImagePath}
        />
        <div className="experiment-card-description">
          {Experiment.Description}
        </div>
      </div>
    </div>
  );
};
