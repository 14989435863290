import { Dynamic } from "../Dynamic.model";

export class Dictionary {
  words: string[];
  lookup: Dynamic;

  constructor() {
    this.words = [];
    this.lookup = {};
  }

  static fromArray(words: string[]): Dictionary {
    const d = new Dictionary();
    // trim words that won't match
    d.words = words
      .filter((word) => word.length > 2 && word.length < 17)
      .map((word) => word.toLocaleLowerCase());

    d.words.forEach((word) => (d.lookup[word] = word));
    return d;
  }

  testWord(word: string) {
    //return this.words.includes(word);
    return this.lookup[word] != null;
  }

  checkWordStartsWith(word: string) {
    return this.words.find((w) => w.startsWith(word)) != undefined;
  }
}
