import "./App.css";

import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import PageNotFoundComponent from "./components/PageNotFound.component";
import { PageDescriptions } from "./experiments";
import About from "./pages/About.page";
import Home from "./pages/Home.page";

function App() {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const navBg = document.getElementsByClassName("nav-bg")[0];

    const scrollTop =
      window.scrollY || (document.documentElement || document.body).scrollTop;

    if (scrollTop >= 20) {
      navBg.classList.remove("bg-hidden");
      navBg.classList.add("bg-visible");
    } else {
      navBg.classList.remove("bg-visible");
      navBg.classList.add("bg-hidden");
    }
  };

  let routes = [
    {
      path: "/",
      component: Home,
    },
    {
      path: "/about",
      component: About,
    },
  ];

  routes = routes.concat(
    Object.keys(PageDescriptions).flatMap((key) => ({
      path: `/${(PageDescriptions as any)[key].Route}`,
      component: (PageDescriptions as any)[key].Component,
    }))
  );

  return (
    <div className="app flex-column flex-grow-1">
      <BrowserRouter>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.component.name}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
          <Route component={PageNotFoundComponent} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
