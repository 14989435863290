import { AdjacentTiles } from "./AdjacentTile.model";
import { Direction } from "./Direction.model";
import { GameBoard } from "./GameBoard.model";

export class Tile {
  letter: string;
  index: number;
  adjacentTiles: AdjacentTiles;

  constructor() {
    this.letter = "";
    this.index = 0;
    this.adjacentTiles = new AdjacentTiles();
  }

  static fromCharacter(char: string, index: number): Tile {
    const t = new Tile();
    t.letter = char;
    t.index = index;
    return t;
  }

  // I don't like this being here, tile shouldn't know about game board
  getAllAdjacent(gameBoard: GameBoard) {
    Object.keys(Direction).forEach((direction: string) => {
      this.adjacentTiles[direction as keyof AdjacentTiles] =
        gameBoard.getNextTile(
          this,
          Direction[direction as keyof typeof Direction]
        );
    });
  }
}
