import "./Header.component.css";

interface props {
  DynamicBackground: boolean;
}

export default function HeaderComponent({ DynamicBackground }: props) {
  return (
    <div className="app-header">
      <nav>
        <div className="nav-bg bg-hidden"></div>
        {!DynamicBackground ? <div className="static-bg"></div> : <></>}
        <div className="title">
          <a href="/">Erich Ray</a>
        </div>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
