import { Dynamic } from "../Dynamic.model";
import { AdjacentTiles } from "./AdjacentTile.model";
import { Dictionary } from "./Dictionary.model";
import { Direction } from "./Direction.model";
import { Tile } from "./Tile.model";

export class GameBoard {
  static numRows = 4;
  static numColumns = 4;

  tiles: Tile[];
  dictionary: Dictionary;
  testedWords: Dynamic;
  knownPrefixes: Dynamic;
  foundWords: string[];
  updateStatus?: (progress: number) => void;

  constructor() {
    this.tiles = [];
    this.dictionary = new Dictionary();
    this.testedWords = {};
    this.knownPrefixes = {};
    this.foundWords = [];
  }

  static fromArray(
    inputArray: string[],
    dictionary?: Dictionary,
    updateStatus?: (progress: number) => void
  ): GameBoard {
    const gameBoard = new GameBoard();
    gameBoard.tiles = inputArray.map((ch, i) => Tile.fromCharacter(ch, i));
    gameBoard.updateStatus = updateStatus;

    if (dictionary != null) {
      gameBoard.dictionary = dictionary;
    }

    gameBoard.loadAdjacencyMap();
    return gameBoard;
  }

  loadAdjacencyMap() {
    this.tiles.forEach((tile) => tile.getAllAdjacent(this));
  }

  getAllRoutes() {
    const now = new Date();
    this.tiles?.forEach((tile, i) => {
      if (this.updateStatus != null) {
        this.updateStatus((i / this.tiles.length) * 100);
      }
      this.getRoutesForTile(tile, []);
    });
    console.log(Math.abs(now.getTime() - new Date().getTime()));
  }

  async getAllRoutesAsync() {
    const now = new Date();
    const routeSearches: Promise<void>[] = [];
    this.tiles?.forEach((tile) => {
      routeSearches.push(this.getRoutesForTile(tile, []));
    });
    await Promise.all(routeSearches);
    console.log(Math.abs(now.getTime() - new Date().getTime()));
  }

  // need to check for Tiles with two letters like A/B
  // as that will cause two separate routes
  async getRoutesForTile(tile: Tile | null, route: number[]) {
    if (tile != null) {
      route.push(tile.index);

      let shouldContinue = true;
      if (route.length > 2) {
        const word = this.getWord(route);

        // check for words that match
        if (!this.testedWords[word]) {
          this.testedWords[word] = word;

          if (this.dictionary.testWord(word)) {
            this.foundWords.push(word);
          }
        }

        // also check if any words start with route
        // so we can eliminate extra cycles for nonsense words
        if (this.knownPrefixes[word] != null) {
          // short circuit if the prefix is bad
          shouldContinue = this.knownPrefixes[word];
        } else {
          const prefixIsValid = this.dictionary.checkWordStartsWith(word);
          this.knownPrefixes[word] = prefixIsValid;
          shouldContinue = prefixIsValid;
        }
      }

      if (shouldContinue) {
        Object.keys(tile.adjacentTiles).forEach((tileDirection: string) => {
          const nextTile =
            tile.adjacentTiles[tileDirection as keyof AdjacentTiles];
          if (nextTile != null && !route.includes(nextTile.index)) {
            this.getRoutesForTile(nextTile, route);

            // remove the last item in the array to start a new
            // route subset
            route.pop();
          }
        });
      }
    }
  }

  // need to check if the next tile is valid, for instance something like
  // Ab- can only be the start of a route
  getNextTile(tile: Tile | null, direction: Direction): Tile | null {
    if (tile != null) {
      switch (direction) {
        case Direction.up:
          return GameBoard.RowIndex(tile) > 0
            ? this.tiles[tile.index - 4]
            : null;
        case Direction.down:
          return GameBoard.RowIndex(tile) < 3
            ? this.tiles[tile.index + 4]
            : null;
        case Direction.left:
          return GameBoard.ColumnIndex(tile) > 0
            ? this.tiles[tile.index - 1]
            : null;
        case Direction.right:
          return GameBoard.ColumnIndex(tile) < 3
            ? this.tiles[tile.index + 1]
            : null;
        case Direction.upleft:
          return this.getNextTile(
            this.getNextTile(tile, Direction.up),
            Direction.left
          );
        case Direction.upright:
          return this.getNextTile(
            this.getNextTile(tile, Direction.up),
            Direction.right
          );
        case Direction.downleft:
          return this.getNextTile(
            this.getNextTile(tile, Direction.down),
            Direction.left
          );
        case Direction.downright:
          return this.getNextTile(
            this.getNextTile(tile, Direction.down),
            Direction.right
          );
      }
    } else {
      return null;
    }
  }

  getWord(route: number[]): string {
    let word = "";
    route.forEach((index) => (word = word.concat(this.tiles[index].letter)));
    return word;
  }

  /*
      need to be able to determine row and
      column in oder to determine possible paths
    */
  static RowIndex(tile: Tile) {
    for (let i = 0; i <= GameBoard.numRows; i++) {
      if (tile.index < GameBoard.numColumns * (i + 1)) return i;
    }

    throw console.error("index was not found");
  }

  static ColumnIndex(tile: Tile) {
    return tile.index % 4;
  }
}
