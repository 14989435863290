export enum Direction {
  up = "up",
  down = "down",
  left = "left",
  right = "right",
  upleft = "upleft",
  upright = "upright",
  downleft = "downleft",
  downright = "downright",
}
